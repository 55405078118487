import { useNavigate } from "react-router-dom"
import { all_footer_packages } from "../utilities/datas"

const Footer = () => {
    const navigate = useNavigate()

    return(
   <div className="footer">
    <div className="footer-data">
    <div className="social-media-container">
        <img className="footer-logo-container" src="/assets/icons/emergehub-logo.svg" alt="emerge-logo"/>
  
        <p className="pt-2">
        Your No 1 coworking space
        </p>
        <div className="footer-icon">
        <img onClick={() => window.open("https://www.instagram.com/emergehub_ng/", "_blank")} className="social-icon"  src="/assets/icons/instagram-icon.png" alt="instagram"/> 
        <img onClick={() => window.open("https://www.facebook.com/emergehubng", "_blank")} className="social-icon" alt="facebook" src="/assets/icons/facebook-icon.png"/> 
        <img onClick={() => window.open("https://www.twitter.com/TheEmergeHub", "_blank")}  className="social-icon"  src="/assets/icons/twitter-icon.png" alt="twitter"/></div>
        
    </div>

    <div className="services">
        <h2 className="services-title">Services</h2>
        <div className="services-text">
        {all_footer_packages.map(i => (<a onClick={(e) => {e.preventDefault(); navigate("/package-detail", {state: i})}} className="footer-link" href="/" ><p>{i.title}</p></a>))}
        </div>
    </div>
    <div className="instagram-section">
    <h2 className="services-title">Products</h2>
    <div className="instagram-feed row">
        {all_footer_packages.slice(0, 6).map(i => (<div className="feed col-6" style={{backgroundImage: `url(${i.image})`, backgroundSize: "cover"}} alt="img"></div>))}
    </div>
    </div>
    </div>
    <div className="m-auto w-50 footer-icon-mobile d-flex justify-content-center pb-5">
        <img className="m-1" onClick={() => window.open("https://www.instagram.com/emergehub_ng/", "_blank")}  src="/assets/icons/instagram-icon.png" alt="instagram"/>
        <img className="m-1" onClick={() => window.open("https://www.facebook.com/emergehubng", "_blank")} alt="facebook" src="/assets/icons/facebook-icon.png"/> 
        <img className="m-1" onClick={() => window.open("https://www.twitter.com/TheEmergeHub", "_blank")}  src="/assets/icons/twitter-icon.png" alt="twitter"/></div>
   </div>
    )
}

export default Footer