import React from "react"



const Header = () => {
    return(
        <div className="grid">
        </div>
    )
}

export default Header