import {useEffect, useRef} from "react"
import AOS from "aos";



const Displays = () => {
  const ref = useRef("display")

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);

    return(
        <div className="display" ref={ref}>
        <div className="d-flex display-first-box" data-aos='fade-right'>
        <div className="right-display-text">
        <p>
        Grind away at our workspace in the heart of Lagos. Create some head space and escape the daily city noise and stress while being productive and effective.
        </p>
        </div>
        <div className="zoom">
        <img className="" alt="img" src="/assets/img/chairs-one.svg" style={{width: "100%", height: "100%"}}/>
        </div>
     
        </div>
        <div className="d-flex display-second-box" data-aos='fade-right'>
        <div className="left-display-text">
        <p>
        Book by the hour or day, get monthly access, or settle into an office for as long as you want.
        </p>
        </div>
        <div className="zoom">
        <img className="" alt="img" src="/assets/img/chairs-two.svg" style={{width: "100%", height: "100%"}}/>
        </div>
        </div>
        </div>
    )
}

export default Displays