import { useState, useEffect } from "react";
import AOS from "aos";


function ScrollButton() {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        AOS.init({
            duration: 1000,
          });
    }, [])


    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 2000){
          setVisible(true)
        } 
        else if (scrolled <= 3000){
          setVisible(false)
        }
      };


      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
      
      window.addEventListener('scroll', toggleVisible);

    return (
        <div className="w-30 m-lg-5 m-sm-0" style={{position: "fixed", bottom: 0, display: visible ? "inline" : "none", zIndex: "1000"}}>
          <button data-aos='fade-in' className="btn border-0 scroll-btn" onClick={scrollToTop} >
            <img src="/assets/icons/scrollBtn.svg" className="scroll-btn" alt="sroll-btn"/></button>  
            <p className="text-white scroll-text">Back to Top</p>
            </div>

    );
  }
  
  export default ScrollButton;