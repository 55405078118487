import { useEffect } from "react"
import { Package, Grid } from "../components"
import { Navbar, Footer } from "../layouts"



const PackageDetail = () => {
    useEffect(() =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
    }, [])
    return (
        <div>
        <Navbar/>
        <Grid/>
       <Package />
       <Footer/>
        </div>
    )
}

export default PackageDetail