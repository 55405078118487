import { useRef, useEffect } from "react"
import { AboutUs, ContactUs, OurMission, OurValue , Grid} from "../components"
import { Navbar, Footer } from "../layouts"



const About = () => {
    useEffect(() =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
    }, [])

    const contactUsRef = useRef(null)

    const aboutRef = useRef(null)

    const handleClick = () => {
        contactUsRef.current.scrollIntoView({ behavior: 'smooth' })
    };

    const handleAboutUs = () => {
        aboutRef.current.scrollIntoView({ behavior: 'smooth' })
    };
    return (
        <div>
        <Navbar contactUsScroll={handleClick} showContactUs={true} />
        <Grid/>
        <AboutUs handleAboutUs={handleAboutUs} />
        <OurMission aboutRef={aboutRef}  />
        <OurValue/>
        <ContactUs contactUsRef={contactUsRef}/>
        <Footer/>
        </div>

    )
}

export default About
