import { Navigate, BrowserRouter as Router ,useRoutes } from "react-router-dom";
import { HomePage, AboutUs, Packages, PackageDetail } from "./pages"
import { Whatsapp, ScrollButton } from "./layouts"



const App = () =>{

return useRoutes([
        { path: "/", element: <Navigate to="/home"/> },
        { path: "/home", element: <HomePage/> },
        { path: "/about-us", element: <AboutUs/> },
        { path: "/packages", element: <Packages/> },
        { path: "/package-detail", element: <PackageDetail/> },
      ])
}
const AppWrapper = () => {

  
    return (
      <Router>
        <Whatsapp/>
        <App />
        <ScrollButton />
      </Router>
    );
  };

export default AppWrapper