
function Whatsapp() {

  const openWhatsApp = () => {
    window.open("https://api.whatsapp.com/send/?phone=2348126881339&text&type=phone_number&app_absent=0&text=Hi i will like to know more about EmergeHub&app_absent=0", '_blank');
  }
  return (
    <div className='w-b text-end w-30'>
            <button style={{zIndex: "1000"}} className="btn border-0" onClick={openWhatsApp}>
            <img className="btn-whatsapp-inner" style={{width: "90px", height: "90px"}} src='/assets/icons/whatsapp4.png' alt="img"/>
            </button>
    </div>
  );
}

export default Whatsapp;