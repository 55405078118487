import { useEffect } from "react"
import { Packages, Grid } from "../components"
import { Navbar, Footer } from "../layouts"



const About = () => {

    useEffect(() =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
    }, [])
    return (
        <div>
        <Navbar/>
        <Grid/>
       <Packages />
       <Footer/>
        </div>

    )
}

export default About