import { useRef, useEffect } from "react"
import { Header, Banner, OurClient, Pricing, Displays, ContactUs, Grid } from "../components"
import { Navbar, Footer } from "../layouts"
import { useLocation } from "react-router-dom"




const HomePage = () => {
const { state } = useLocation()
const scrolled = document.documentElement.scrollTop;
     
    useEffect(() =>{
        if(state && state.moveToContact){
            contactUsRef.current.scrollIntoView({ behavior: 'smooth' })
            window.history.replaceState({}, document.title)

           }else{
               window.scrollTo({
                   top: 0, 
                   behavior: 'smooth'
                 });
           }
    }, [state, scrolled])

    const contactUsRef = useRef(null)

    const servicesRef = useRef(null)

    const handleClick = () => {
        contactUsRef.current.scrollIntoView({ behavior: 'smooth' })
    };

    const handleServiceClick = () => {
        servicesRef.current.scrollIntoView({ behavior: 'smooth' })
    };


    return (
        <>
        <Navbar contactUsScroll={handleClick}  servicesScroll={handleServiceClick} showContactUs={true} showServices={true}/>
        <Grid/>
        <Header/>
        <Displays/>
        <OurClient/>
        <Pricing servicesRef={servicesRef}/>
        <Banner/>
        <ContactUs contactUsRef={contactUsRef}/>
        <Footer/>
        </>

    )
}

export default HomePage