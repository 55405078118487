



const OurValue = () => {
    return(
        <div className="our-value">
            <h1 className="our-value-header-text text-center">Our Values</h1>
            <div className="our-value-col">
                <img className="our-value-icon" src="/assets/icons/our-value-crown-icon.svg" alt="afford"/>
            <div className="our-value-text-div">
            <h1 className="our-value-header">
            Ambience
            </h1>
            <p className="our-value-text">
            We believe where we work is as important as the work we do and here, we embrace the 
            concept of workplace wellness by creating a warm, welcoming and clutter-free ambience 
            that inspires and boosts creativity. We want you to not only love what you do but also love where you work.
            </p>
            </div>
            </div>
            <div className="our-value-col">
                <img className="our-value-icon" src="/assets/icons/our-value-swap-icon.svg" alt="customer"/>
            <div className="our-value-text-div">
            <h1 className="our-value-header">
            Flexibility
            </h1>
            <p className="our-value-text">
            We understand the need for business owners to keep costs lean and distractions 
            low while maximizing productivity and focusing on driving their business forward. 
            Our flexible and cost-effective plans offer the right amount of support and structure
            to help you and your business grow, with an array of services and amenities that will
            enhance your work experience
            </p>
            </div>
            </div>
            <div className="our-value-col">
                <img className="our-value-icon" src="/assets/icons/our-value-people-icon.svg" alt="prime"/>
            <div className="our-value-text-div">
            <h1  className="our-value-header">
            Community
            </h1>
            <p className="our-value-text">
            Our doors are open to founders, freelancers, creative, professionals and everyone who 
            needs an enabling environment to create, work, study, start a business or run an existing one. 
            </p>
            </div>
            </div>
            <div className="our-value-col">
                <img className="our-value-icon" src="/assets/icons/our-value-medal-star-icon.svg" alt="prime"/>
            <div className="our-value-text-div">
            <h1  className="our-value-header">
            Success
            </h1>
            <p className="our-value-text">
            Beyond just a physical space to work, our aim is to provide an enabling
            environment that fosters positive connections and empowers our members to succeed. 
            </p>
            </div>
            </div>
            <div className="our-value-col">
                <img className="our-value-icon" src="/assets/icons/our-value-sms-icon.svg" alt="prime"/>
            <div className="our-value-text-div">
            <h1  className="our-value-header">
            Admin Support
            </h1>
            <p className="our-value-text">
            Our professional client support team is waiting to take those tedious
            clerical tasks off your plate, so you can focus on what matters. 
            </p>
            </div>
            </div>
        </div>
    )
}

export default OurValue