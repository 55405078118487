import { all_packages } from "../utilities/datas" 
import { useNavigate } from "react-router-dom"

const Packages = () => {

    const navigate = useNavigate()

    return(
       <div className="row d-flex justify-content-center w-100 m-auto">
        {
            all_packages.map(i => (
                <div className="package-card m-3 col-lg-4 col-md-3 col-sm-12" key={i.id} >
                   <img className="package-image mx-auto d-block m-2" src={i.image} alt="package"/> 
                   <div className="package-title text-center">{i.title}</div>
                   <div className="package-amount text-center">{i.amount}</div>
                   <div className="package-quantity text-center">{i.quantity}</div>
                   <div className="m-5 package-list-section">
                    {
                       i.qualities.map(text => (<div key={text} className="d-flex"><div className="package-qualities-dot mt-2"></div> <div className="package-qualities" key={text}>{text}</div></div>))
                    }
                   </div>
                  <div className="text-center mb-4"> <button onClick={() => navigate("/package-detail", {state: i})} className="rounded btn-primary book-package-btn mx-auto">Book Now</button></div>
                </div>
            ))
        }
       </div>
    )
}

export default Packages