import {useEffect} from "react"
import AOS from "aos";



const AboutUs = ({handleAboutUs}) => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);
    return(
        <div className="about-us">

<div className="about-us-contaner p-lg-5 p-sm-0" data-aos='fade-up'>
                <h1>
                About Us
                </h1>
                <div className="about-us-text mt-4">
                EmergeHub is a serviced coworking space located at Anthony Village, Lagos,
            Nigeria. We provide individuals and businesses with a serene and comfortable
            workspace to enhance business productivity with easy access to business
            services and opportunities to connect and network with members of our
            growing community.
                </div>
                <div className="about-us-below">
                <div>
            <text className="readmore-text">Read more</text>
           <img className="readmore-img" onClick={handleAboutUs} src="/assets/icons/readmore-icon.svg" alt="readmore" />
            </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs