import { useState } from "react";
import { useLocation } from "react-router-dom"
//import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { InterswitchPay } from 'react-interswitch'
import { Modal, ModalBody, ModalFooter, Col, Row, Input, Label, ModalHeader } from "reactstrap"

const Packages = () => {
    const { state } = useLocation();
    const vat = parseFloat(process.env.REACT_APP_VAT)
    const fwChat = parseFloat(process.env.REACT_APP_PAYSTACK_CHARGE)
    const [modalOpen, setModalOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [name, setName] = useState("")
    const [quantity, setQuantity] = useState(1)
    const payAmount = ((state.amountToPay * quantity) * vat)/100
    const chargeAmount = ((state.amountToPay * quantity) * fwChat)/100
    const charges = payAmount + chargeAmount
    const amount = ((state.amountToPay * quantity) + charges) * 100

    const paymentParameters = {
      merchantCode: 'MX71987',
      payItemID: 'Default_Payable_MX71987',
      customerEmail: email,
      redirectURL: 'https://emergehub.com.ng/packages',
      text: 'Pay Now',
      mode: 'LIVE',
      customerMobileNo: phone.toString(),
      customerName: name,
      payItemName: `${quantity} of ${state.title}`,
      transactionReference: Date.now().toString(),
      amount: amount.toString(),
     /* style: {
          width: '200px',
          height: '40px',
          border: 'none',
          color: '#fff',
          backgroundColor: '#ff0000'
      },*/
      callback: (response) => {
        console.log('response: ', response)
      }
    }

   /* const configs = {
      public_key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      tx_ref: Date.now(),
      amount: (state.amountToPay * quantity) + charges,
      currency: 'NGN',
      payment_options: 'card,mobilemoney,ussd',
      customer: {
        email: email,
        phonenumber: phone,
        name: name,
      },
      customizations: {
        title: state.title,
        description: `Payment for ${state.title}`,
        logo: state.image,
        quantity: quantity
      },
    };

    const handleFlutterPayment = useFlutterwave(configs);

    const makePayment = () => {
      try {
        if(!email || !phone || !name){
          alert("Please fill all required space")
        }else{
          handleFlutterPayment({
            callback: (response) => {
               console.log(response);
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
          });
        }
      } catch (error) {
        console.log(error)
      }
    }*/

    const handlePayment = () => {
      try {
          setModalOpen(true)
  
      } catch (error) {
        console.log(error)
      }
    }

    return(
        <>
       <div className="package-detail">
          <div className="package-detail-img">
            <div className="rounded " style={{backgroundImage: `url(${state.image})`, backgroundSize: "contain", backgroundRepeat: "no-repeat"}} alt="img" ></div>
          </div>
          <div className="package-detail-text-section">
          <div className="package-detail-title">{state.title}</div>
                  <div className="d-flex"> <div className="package-detail-amount">{state.amount}</div>
                   <div className="package-detail-quantity">{state.quantity}</div></div>
          <div className="m-5 package-detail-quality">
                    {
                       state.qualities.map((text, index) => (<div key={index} className="d-flex"><div className="package-qualities-dot mt-2"></div> <div className="package-qualities" key={text}>{text}</div></div>))
                    }
                   </div>
                   <button
                   className="btn package-detail-button" 
                    onClick={handlePayment}
                   >
                    Pay Now
                   </button>
          </div>
       
       </div>
       <div className="text-center">
       <button
                   className="btn package-detail-button-phone" 
                    onClick={handlePayment}
                   >
                    Pay Now
                   </button>
        </div>
        <Modal
        size="md"
        isOpen={modalOpen}
        centered
        backdrop="static"
        keyboard={false}
        onClosed={() => {setEmail(""); setName(""); setPhone(""); setQuantity(1)}}
      >
        <ModalHeader className="d-flex justify-content-end">
          <div >
          <button className="btn border" onClick={() => setModalOpen(false)}><b>X</b></button>
          </div>
        </ModalHeader>
        <ModalBody>
        <Row className="">
              <Col xl={12} lg={12} md={12} sm={12}>
                <Label className="mt-3" for="exampleName"><b>Name*</b></Label>

                <Input
                  required
                  className="payment-form"
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                  type="text"
                  name="fname"
                  placeholder="Emergehub"
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Label className="mt-3" for="exampleName"><b>Email*</b></Label>

                <Input
                  required
                  className="payment-form"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                  type="email"
                  name="fname"
                  placeholder="emergehub@gmail.com"
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Label className="mt-3" for="exampleName"><b>Phone Number*</b></Label>

                <Input
                  required
                  className="payment-form"
                  value={phone}
                  onChange={({ target: { value } }) => setPhone(value)}
                  type="text"
                  name="lname"
                  placeholder="+234812345678"
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Label className="mt-3" for="exampleName"><b>Quantity*</b></Label>

                <Input
                  required
                  className="payment-form"
                  value={quantity}
                  onChange={({ target: { value } }) => setQuantity(value)}
                  type="number"
                  name="lname"
                  placeholder="2"
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter className="">
        {quantity > 0 && name && email && phone && <InterswitchPay {...paymentParameters} className="btn btn-primary" />}
        {/*<Button color="primary" onClick={makePayment}>
            Pay Now
          </Button>*/}
        </ModalFooter>
      </Modal>
        </>
    )
}

export default Packages