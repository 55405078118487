import {useEffect} from "react"
import AOS from "aos";
import {useNavigate} from "react-router-dom"



const Header = () => {
  const navigate = useNavigate()


    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);

    return(
        <div className="header">
            <div className="header-contaner p-lg-5 p-sm-0" data-aos='fade-up'>
                <h1>
                Work should be<br/> <span>productive,</span> let’s help <br/>you achieve that...
                </h1>
                <p className="mt-4">
                Whether you are looking for a desk to build a <br/> dream on or offices for your team, EmergeHub’s <br/> flexible work space and collegial atmosphere <br/> makes anything possible.
                </p>
                <div className="header-below">
                    <div className="col-lg-3 col-md-12 col-sm-12">
                   <button className="btn btn-primary" onClick={() => navigate("/packages")}>Book Space</button> 

                   </div>
                   <div className="col-lg-9 col-md-12 col-sm-12">
                   <img alt="teddy" src="/assets/img/head-face.svg" className="teddy"/>

                   </div>
                </div>
            </div>
        </div>
    )
}

export default Header