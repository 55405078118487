import {useEffect} from "react"
import AOS from "aos";



const OurMission = ({aboutRef}) => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);

    return(
        <div className="our-mission" ref={aboutRef}>
        <div className="d-flex our-mission-first-box" data-aos='fade-right'>
        <div className="right-our-mission-text">
        <p>
        EmergeHub was established in 2019 as a community work hub that provides individuals, entrepreneurs, start-ups, freelancers, and other creatives with state-of-the-art shared serviced office spaces, exceptional legal and human resource advisory services, innovative technology, and friendly customer services.</p>

<p>We are a hub committed to creativity, collaboration, and business sustainability; our serviced offices are designed to provide an environment where you can be happy and thrive as an individual or business owner.
        </p>
        </div>
        <div className="our-mission-img">
        <img className="" alt="img" src="/assets/img/our-mission-first-img.png" style={{ width: "100%"}}/>
        </div>
     
        </div>
        <div className="d-flex our-mission-second-box" data-aos='fade-right'>
        <div className="left-our-mission-text">
        <h2 className="our-mission-title">
        Our Mission
        </h2>
        <p>
        EmergeHub’s mission is to be a leading co-working hub in Africa, with a strong commitment to providing a community of individuals, entrepreneurs, start-ups, creatives, and freelancers with appropriate tools to achieve their individual/business objectives. </p>

<p>This we hope to achieve by providing workspaces and business advisory services that will fuel their creativity, enhance collaboration, and improve their bottom line.
        </p>
        </div>
        <div className="our-mission-img">
        <img className="" alt="img" src="/assets/img/our-mission-secong-img.png" style={{ width: "100%"}}/>
        </div>
        </div>
        </div>
    )
}

export default OurMission