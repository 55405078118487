import { useEffect, useState } from "react";
import AOS from "aos"
import { Carousel } from "react-bootstrap";
import { our_clients } from "../utilities/datas";



const OurClient = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

 
  
 
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);


      const controlFunction = (type) => {
        if(type === "prev"){
          if(index === 0){
            setIndex(our_clients.length - 1)
          }else{
          setIndex(index-1)
          }
        }else{
          if(index === our_clients.length - 1){
            setIndex(0)
          }else{
          setIndex(index+1)
          }
        }
      }
      
    return(
        <div className="our-client">
          <div className="client-story" data-aos="fade-in">
        <div className="text-center text-white client-top-div"> <h4 className="client-title"> What our Clients are saying</h4></div> 
        <div className="d-flex justify-content-between control-button">
       <button onClick={e => {controlFunction("prev")}} className="prev-icon"><span> <img className="" src="/assets/icons/prev-icon.svg" alt="prev"/></span></button> 
       <button onClick={e => {controlFunction("next")}}  className="next-icon"><span> <img className="" src="/assets/icons/next-icon.svg" alt="next"/></span></button> 
        </div>
      <Carousel className="m-auto" activeIndex={index} onSelect={handleSelect} indicators={false}  controls={false}>

      {our_clients.map(i => (<Carousel.Item className="caro" interval={2000} key={i.index} tabIndex={i.index}>
        <div className="text-center text-white comment pt-5">{i.comment}</div>
        <div className="carousel-image text-white pt-4">
        <div className="">
        <img
          className=""
          src={i.image}
          alt="Slide"
          style={{width: "100px", height: "100px", borderRadius: "50px"}}
        />
        </div>
          <div className="text-center">
        <h3 className="client-name">{i.name}</h3>
        <p className="client-profession">{i.profession}</p>
        </div>
        </div>
      </Carousel.Item>))}
    </Carousel>
    </div>
   <div className="d-flex m-0 justify-content-center mt-3 pt-5">{our_clients.map(i => (<div onClick={(e) => handleSelect(i.index, e)} className={i.index === index ? "active-indicator" : "indicator"}></div>))}</div> 
        </div>
    )
}

export default OurClient