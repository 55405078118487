
const ContactUs = ({contactUsRef}) => {


    return(
        <div className="contact-us p-2" ref={contactUsRef}>
            <div className="contact-us-first">
                <div className="headers">Get in touch with us</div>
                <div>
                <form action="https://formsubmit.co/5b2170d1d70cc8a57b00cc1232c3a4f2" method="POST">
                    <input className="form-control get-in-touch-form" placeholder="Name" name="name" required/>
                    <input className="form-control get-in-touch-form" type="number" name="number" placeholder="Phone Number" required/>
                    <input className="form-control get-in-touch-form" type="email" name="email" placeholder="Email Address" required/>
                    <select className="form-control get-in-touch-form" placeholder="How did you hear about us?" name="how you heard about us" required>
                    <option selected style={{color: "#D9D9D9 !important"}} disabled>How did you hear about us?</option>
                    <option>Facebook</option>
                    <option>Twitter</option>
                    <option>Instagram</option>
                    <option>Whatsapp</option>
                    <option>Ads</option>
                    <option>Friend</option>
                    </select>
                    <textarea placeholder="Message" className="form-control get-in-touch-form" name="message" id="exampleFormControlTextarea1" rows="10" required></textarea>
                    <button className="btn btn-primary mt-3" type="submit">Send Message</button>
                </form>
                </div>
            </div>
            <div className="contact-us-second">
           <div className="d-flex mt-3"><div className="contact-icon"><img alt="phone" src="/assets/icons/contact-us-phone.svg" className="contact-us-phone"/></div> <div className="info-text">08126881339, 08132515911</div></div> 
           <div className="d-flex mt-3"><div className="contact-icon"><img alt="sms" src="/assets/icons/contact-us-sms.svg" className="contact-us-sms"/></div> <div className="info-text"> info@emergehub.com.ng</div></div>
           <div className="d-flex mt-3"><div className="contact-icon"><img alt="clock" src="/assets/icons/contact-us-clock.svg" className="contact-us-clock"/></div> <div className="info-text"> Monday to Saturday <br/>9:00am – 5:00pm</div></div> 
           <div className="d-flex mt-3 mb-3"><div className="contact-icon"><img alt="location" src="/assets/icons/contact-us-location.svg" className="contact-us-location"/> </div> <div className="info-text">4 Ayanboye Street, Off Faramobi Ajike Street <br/>
            Anthony Village, Lagos State,</div></div>
            <div className="mapouter">
            <div className="gmap_canvas">
            <iframe title="map-address"className="ifame" id="gmap_canvas" src="https://maps.google.com/maps?q=4%20Ayanboye,%20St%20Athony%20105102,%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://fmovies-online.net">i</a>
            <br/><a href="https://www.embedgooglemap.net">Get Direction</a>
            </div></div>
            </div>
        </div>
    )
}

export default ContactUs