import Button from 'react-bootstrap/Button';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link, useNavigate, useLocation} from "react-router-dom"
import { useState } from 'react';

function NavScrollExample({contactUsScroll}) {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const { pathname } = useLocation();
  
  
  const navigate = useNavigate()

  const navigator = async (e) => {
    e.preventDefault()
    if(pathname === "/home" || pathname === "/about-us"){
      contactUsScroll()
    }else{
      await navigate("/home", {state: {moveToContact: true}})
      await contactUsScroll()
    }
  }
  return (
    <div className='w-100 nav-b'>
    <Navbar bg="white" expand="lg" expanded={isNavbarOpen}>
      <Container fluid className=''>
        <Navbar.Brand href="/home" className='nav-logo'><img src='/assets/icons/emergehub-logo.svg' height={68} width={157} alt='logo'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setIsNavbarOpen(!isNavbarOpen)} className=' border-0 noborder'>{isNavbarOpen ? <img src='/assets/icons/navbar-icon.svg' alt='logo'/> : <img src='/assets/icons/navbar-icon.svg' alt='logo'/>}</Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >   
          </Nav>
            <Link to="/home" className="d-flex link nav-right-data width-100 margin-3">HOME</Link>
            <Link to="/about-us" className="d-flex link nav-right-data width-100 margin-3">ABOUT US</Link>
            <Link to="/packages" className="d-flex link nav-right-data width-100 margin-3">SERVICES</Link>
            <a href='/' onClick={navigator} className="d-flex link nav-right-data width-100 margin-3">CONTACT US</a>
            <a href="tel:+2348126881339" className="d-flex link nav-right-data  width-100 margin-3"><img src='/assets/icons/phone-icon.svg' alt='phone'/></a>
            <Button onClick={() => navigate("/packages")} className=" btn btn-primary text-secondary nav-right-btn width-100 p-3">Book Space</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}

export default NavScrollExample;