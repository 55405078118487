export const our_clients = [
  {
    index: 0,
    image: "/assets/img/trillionbucks.png",
    name: "Adenike",
    profession: "C.E.O. Trillionbucks",
    comment:
      "The EmergeHub is Trillionbuck’s HQ! Super-clean environment, helpful and supportive staffs. I recommend EmergeHub to any freelancer or Entrepreneur looking for a nest for their team",
  },
  {
    index: 1,
    image: "/assets/img/jiddyak.png",
    name: "Emmanuel Akinola",
    profession: "C.E.O. Jiddyak Corporate Services",
    comment:
      "Imagine running your business in a clean, safe, and tech-savvy environment, an atmosphere where everything works perfectly, so conducive that all your out-of-the-box ideas naturally flows, a well-structured, corporate, and customer friendly environment. This is what EmergeHub represents!",
  },
  {
    index: 2,
    image: "/assets/img/okab.png",
    name: "Francis Arowojulo",
    profession: "C.E.O. OKAB Travels and Tour",
    comment: "Nice office space and friendly staffs. I recommend!",
  },
  {
    index: 3,
    image: "/assets/img/pois.png",
    name: "Joe Olabode",
    profession: "C.E.O. Pois Consult",
    comment:
      "I have so far been pleased with the services rendered by EmergeHub. I also believe they can scale up if they continue this way",
  },
  {
    index: 4,
    image: "/assets/img/vbs.png",
    name: "Oluwaseun Olubuyide",
    profession: "C.E.O. VBS",
    comment:
      "The Hub is a serene environment to work. It caters for startups and businesses that want to focus on their core functions without thinking about rent issues in Lagos. We have been with EmergeHub since 2019",
  },
  {
    index: 5,
    image: "/assets/img/jetride.png",
    name: "Jetride",
    profession: " ",
    comment:
      "If you find that isolation and the disorganization of home reduce your efficiency, then change to a community office where you can increase efficiency without losing comfort. Switch to EmergeHub!",
  },
  {
    index: 6,
    image: "/assets/img/ilori.png",
    name: "Onaopemipo Ilori",
    profession: " ",
    comment:
      "It has a serene environment to work. Best place for work and networking",
  },
  {
    index: 7,
    image: "/assets/img/ovedum.png",
    name: "Kene Onyedum",
    profession: " ",
    comment: "It’s the ambience for me",
  },
];

export const packages = [
  {
    id: "dailyaccess",
    amountToPay: 4000,
    title: "DAILY ACCESS",
    amount: "₦4,000",
    quantity: "PER DAY",
    image: "/assets/img/daily-access.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Communal Space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "privateoffice",
    amountToPay: 100000,
    title: "PRIVATE OFFICE",
    amount: "₦100,000",
    quantity: "PER MONTH",
    image: "/assets/img/private-office.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Lockers",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "4-Hour Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
  {
    id: "trainingspace",
    amountToPay: 10000,
    title: "TRAINING/EVENT SPACE",
    amount: "₦10,000",
    quantity: "PER HOUR",
    image: "/assets/img/training-event.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply for the facilitator",
      "Fully Air-Conditioned Space",
      "TV For Projection",
      "Marker Board",
      "Printing, Scanning & Photocopying (Discounted)",
      "25 – 30 seats",
      "Adequate Parking Space",
    ],
  },
];

export const all_footer_packages = [
  {
    id: "dailyaccess",
    amountToPay: 4000,
    title: "DAILY ACCESS",
    amount: "₦4,000",
    quantity: "PER DAY",
    image: "/assets/img/daily-access.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Communal Space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "superdesk",
    amountToPay: 20000,
    title: "SUPER DESK",
    amount: "₦20,000",
    quantity: "PER MONTH",
    image: "/assets/img/superdesk.svg",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "Communal Space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "hotdesk",
    amountToPay: 25000,
    title: "HOT DESK",
    amount: "₦25,000",
    quantity: "PER MONTH",
    image: "/assets/img/hotdesk.svg",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "Communal Space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "dedicateddesk",
    amountToPay: 35000,
    title: "DEDICATED DESKS",
    amount: "₦35,000",
    quantity: "PER MONTH",
    image: "/assets/img/dedicated.svg",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Locker",
      "3-Hour Meeting Room Voucher",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "Adequate Parking Space",
    ],
  },
  {
    id: "privateoffice",
    amountToPay: 100000,
    title: "PRIVATE OFFICE",
    amount: "₦100,000",
    quantity: "PER MONTH",
    image: "/assets/img/private-office.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Lockers",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "4-Hour Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
  {
    id: "standardvirtual",
    amountToPay: 90000,
    title: "STANDARD VIRTUAL OFFICE",
    amount: "₦90,000",
    quantity: "PER ANUM",
    image: "/assets/img/standard-virtual.png",
    qualities: [
      "Reputable Business Address",
      "Customer Care Services",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Handling & Forwarding Services",
      "96-Hour Per Annum Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
  {
    id: "conferenceroom",
    amountToPay: 4000,
    title: "CONFERENCE ROOM",
    amount: "₦4,000",
    quantity: "PER HOUR",
    image: "/assets/img/conference-room.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "TV For Projection",
      "Marker Board",
      "Printing, Scanning & Photocopying (Discounted)",
      "6-man meeting space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "trainingspace",
    amountToPay: 10000,
    title: "TRAINING/EVENT SPACE",
    amount: "₦10,000",
    quantity: "PER HOUR",
    image: "/assets/img/training-event.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply for the facilitator",
      "Fully Air-Conditioned Space",
      "TV For Projection",
      "Marker Board",
      "Printing, Scanning & Photocopying (Discounted)",
      "25 – 30 seats",
      "Adequate Parking Space",
    ],
  },
  {
    id: "enterprisevirtual",
    amountToPay: 150000,
    title: "ENTERPRISE VIRTUAL OFFICE",
    amount: "₦150,000",
    quantity: "PER ANUM",
    image: "/assets/img/enterprise-virtual.png",
    qualities: [
      "Reputable Business Address",
      "Customer Care Services",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Handling & Forwarding Services",
      "144-Hour Per Annum Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
];

export const all_packages = [
  {
    id: "dailyaccess",
    amountToPay: 4000,
    title: "DAILY ACCESS",
    amount: "₦4,000",
    quantity: "PER DAY",
    image: "/assets/img/daily-access.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Communal Space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "superdesk",
    amountToPay: 20000,
    title: "SUPER DESK (2 DAYS/WEEK)",
    amount: "₦20,000",
    quantity: "PER MONTH",
    image: "/assets/img/superdesk.svg",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "Communal Space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "hotdesk",
    amountToPay: 25000,
    title: "HOT DESK (3 DAYS/WEEK)",
    amount: "₦25,000",
    quantity: "PER MONTH",
    image: "/assets/img/hotdesk.svg",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "Communal Space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "dedicateddesk",
    amountToPay: 35000,
    title: "DEDICATED DESKS",
    amount: "₦35,000",
    quantity: "PER MONTH",
    image: "/assets/img/dedicated.svg",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Locker",
      "3-Hour Meeting Room Voucher",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "Adequate Parking Space",
    ],
  },
  {
    id: "privateoffice",
    amountToPay: 100000,
    title: "PRIVATE OFFICE",
    amount: "₦100,000",
    quantity: "PER MONTH",
    image: "/assets/img/private-office.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Lockers",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "4-Hour Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
  {
    id: "privateoffice2",
    amountToPay: 130000,
    title: "PRIVATE OFFICE",
    amount: "₦130,000",
    quantity: "PER MONTH",
    image: "/assets/img/private-two.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Lockers",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Forwarding Services",
      "4-Hour Per Week Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
  {
    id: "standardvirtual",
    amountToPay: 100000,
    title: "STANDARD VIRTUAL OFFICE PLAN",
    amount: "₦90,000",
    quantity: "PER ANUM",
    image: "/assets/img/standard-virtual.png",
    qualities: [
      "Reputable Business Address",
      "Customer Care Services",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Handling & Forwarding Services",
      "96-Hour Per Annum Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
  {
    id: "enterprisevirtual",
    amountToPay: 150000,
    title: "ENTERPRISE VIRTUAL OFFICE PLAN",
    amount: "₦150,000",
    quantity: "PER ANUM",
    image: "/assets/img/enterprise-virtual.png",
    qualities: [
      "Reputable Business Address",
      "Customer Care Services",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "Printing, Scanning & Photocopying (Discounted)",
      "Mail Handling & Forwarding Services",
      "144-Hour Per Annum Meeting Room Voucher",
      "Adequate Parking Space",
    ],
  },
  {
    id: "conferenceroom",
    amountToPay: 4000,
    title: "CONFERENCE ROOM/MEETING ROOM",
    amount: "₦4,000",
    quantity: "PER HOUR",
    image: "/assets/img/conference-room.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply",
      "Fully Air-Conditioned Space",
      "TV For Projection",
      "Marker Board",
      "Printing, Scanning & Photocopying (Discounted)",
      "6-man meeting space",
      "Adequate Parking Space",
    ],
  },
  {
    id: "trainingspace",
    amountToPay: 10000,
    title: "TRAINING/EVENT SPACE",
    amount: "₦10,000",
    quantity: "PER HOUR",
    image: "/assets/img/training-event.png",
    qualities: [
      "Constant Power Supply",
      "Internet Supply for the facilitator",
      "Fully Air-Conditioned Space",
      "TV For Projection",
      "Marker Board",
      "Printing, Scanning & Photocopying (Discounted)",
      "25 – 30 seats",
      "Adequate Parking Space",
    ],
  },
];

export const instagramFeeds = [
  {
    id: "mrone",
    name: "Mr One",
  },
  {
    id: "mrtwo",
    name: "Mr Two",
  },
  {
    id: "mrthree",
    name: "Mr Three",
  },
  {
    id: "mrfour",
    name: "Mr Four",
  },
  {
    id: "mrfive",
    name: "Mr Five",
  },
  {
    id: "mrsix",
    name: "Mr Six",
  },
];
