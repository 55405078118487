import { useEffect } from "react";
import AOS from "aos";



 


const BannerAds = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);
      
    return(
        <div className="banner" data-aos="fade-up">
            <div className="banner-col">
                <img className="banner-icon" src="/assets/icons/afford.svg" alt="afford"/>
            <div className="banner-text-div">
            <h1 className="banner-header">
            Affordability
            </h1>
            <p className="banner-text">
            Our pricing isn’t designed to nickel and dime. The things that matter most are all included in your monthly subscription.
            </p>
            </div>
            </div>
            <div className="banner-col">
                <img className="banner-icon" src="/assets/icons/customer.svg" alt="customer"/>
            <div className="banner-text-div">
            <h1 className="banner-header">
            Customer Support
            </h1>
            <p className="banner-text">
            Our customer service staff are ever ready to provide adequate client support to ensure customer satisfaction.
            </p>
            </div>
            </div>
            <div className="banner-col">
                <img className="banner-icon" src="/assets/icons/prime.svg" alt="prime"/>
            <div className="banner-text-div">
            <h1  className="banner-header">
            Prime Location
            </h1>
            <p className="banner-text">
            EmergeHub is the home of developing businesses and teams, right in the heart of the city. 
            </p>
            </div>
            </div>
        </div>
    )
}

export default BannerAds